import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions">
            <h1>Terms and Conditions</h1>
            <p>Last updated: November 1, 2024</p>

            <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using EVDWEB (the "Service") operated by Evolution Design Inc ("us", "we", or "our").</p>

            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.</p>

            <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>

            <h2>1. Content Ownership and Copyright</h2>
            <p>1.1 All content on this website, including but not limited to text, graphics, logos, images, audio clips, digital downloads, data compilations, and software, is the property of Evolution Design Inc or its content suppliers and is protected by international copyright laws.</p>
            <p>1.2 The compilation of all content on this site is the exclusive property of Evolution Design Inc and is protected by international copyright laws.</p>
            <p>1.3 All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</p>

            <h2>2. User-Generated Content</h2>
            <p>2.1 By posting, uploading, inputting, providing, or submitting your content to the Service, you grant Evolution Design Inc and its affiliated companies and necessary sublicensees a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>
            <p>2.2 You represent and warrant that you own or otherwise control all of the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate these Terms and will not cause injury to any person or entity; and that you will indemnify Evolution Design Inc for all claims resulting from content you supply.</p>

            <h2>3. Prohibited Uses</h2>
            <p>You may not use the Service for any illegal purpose or to violate any laws in your jurisdiction (including but not limited to copyright laws).</p>

            <h2>4. Limitation of Liability</h2>
            <p>In no event shall Evolution Design Inc, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>

            <h2>5. Changes</h2>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect.</p>

            <h2>6. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at info@evolutiondesigninc.com .</p>
        </div>
    );
};

export default TermsAndConditions;