import React, { useState } from 'react';
import { push_contact } from "../actions/push_contact_to_cloud";

const Form = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await push_contact(formData);
            // Clear form after successful submission
            setFormData({
                name: '',
                phone: '',
                email: '',
                message: ''
            });
            alert('Message sent successfully!');
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Failed to send message. Please try again.');
        }
    };

    return (
        <div className="home_contact">
            <form onSubmit={handleSubmit}>
                <input
                    className="form-control form-control-lg"
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Your Name*"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    aria-label=".form-control-lg example"
                />
                <input
                    className="form-control form-control-lg"
                    name="phone"
                    id="phone"
                    type="number"
                    placeholder="Your Phone No"
                    value={formData.phone}
                    onChange={handleChange}
                    aria-label=".form-control-lg example"
                />
                <input
                    className="form-control form-control-lg"
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Your Email*"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    aria-label=".form-control-lg example"
                />
                <textarea
                    className="form-control pt-4"
                    name="message"
                    id="message"
                    placeholder="Your Message"
                    rows="3"
                    value={formData.message}
                    onChange={handleChange}
                ></textarea>
                <div className="btn_group">
                    <button type="submit" className="btn olive">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default Form;
