export const push_contact = async (formData) => {
    try {
        console.log(JSON.stringify(formData))
        const response = await fetch('https://zpenyvg1n1.execute-api.us-west-2.amazonaws.com/prd/contact_post', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)

        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error submitting form:', error);
        throw error;
    }
};